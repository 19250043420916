import axios from "axios";

export default {
    dashboardStats: () => {
        const options = {
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}partners/${process.env.REACT_APP_PARTNER}/people_stats/`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: process.env.REACT_APP_API_KEY
            }
        };

        return axios.request(options)
            
    },

    createScreening: () => {
        const options = {
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}screenings/`,
            headers: {
                Authorization: process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
            },
            data: { partner: process.env.REACT_APP_PARTNER_ID }
        };

        return axios.request(options)
            
    },

    uploadImages: (image_file, screeningId, currentSection) => {
        
        const form = new FormData();

        form.append(
            "media",
            image_file
        );
        form.append("key", `${currentSection}`);
        form.append("partner", process.env.REACT_APP_PARTNER_ID);
        form.append("api_token_required", "true");

        const options = {
            method: "PUT",
            url: `${process.env.REACT_APP_API_URL}screenings/${screeningId}/`,
            params: { partner: process.env.REACT_APP_PARTNER },
            headers: {
                Authorization: process.env.REACT_APP_API_KEY,
                "Content-Type":
                    "multipart/form-data; boundary=---011000010111000001101001",
            },
            data: form,
        };


        return axios.request(options)
    },

    finishUpload: (screeningId) => {
        const options = {
            method: 'PUT',
            url: `${process.env.REACT_APP_API_URL}screenings/${screeningId}/`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: process.env.REACT_APP_API_KEY
            },
            data: {
                partner: process.env.REACT_APP_PARTNER_ID,
                upload_finished: true,
                
            }
        };

        return axios.request(options)
    },

    updateUpload: (screeningId, meta_data, patient_details) => {

        const options = {
            method: 'PUT',
            url: `${process.env.REACT_APP_API_URL}screenings/${screeningId}/`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: process.env.REACT_APP_API_KEY
            },
            data: {
                partner: process.env.REACT_APP_PARTNER_ID,
                patient_details: patient_details,
                user_meta: {
                    meta_data: meta_data
                    
                },
                upload_finished: false,
                
            }
        };

        return axios.request(options)
            
    },

}



